import './CheckoutForm.scss'

import React, { useState, useEffect } from "react";
import {
    CardElement,
    useStripe,
    useElements
} from "@stripe/react-stripe-js";

export default function CheckoutForm({ productId, price, onFormInitializationError }) {
    const [succeeded, setSucceeded] = useState(false);
    const [error, setError] = useState(null);
    const [formInitializationError, setFormInitializationError] = useState(false);
    const [processing, setProcessing] = useState('');
    const [disabled, setDisabled] = useState(true);
    const [email, setEmail] = useState('');
    const [clientSecret, setClientSecret] = useState('');
    const stripe = useStripe();
    const elements = useElements();

    useEffect(() => {
        // Create PaymentIntent as soon as the page loads
        async function postCreatePaymentIntent() {
            try {
                const data = await fetch("https://api.joroze.com/create-payment-intent", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        items: [productId]
                    })
                }).then(res => res.json());

                setClientSecret(data);
            } catch (error) {
                setFormInitializationError(true)
            }
        }

        postCreatePaymentIntent();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (formInitializationError) {
            onFormInitializationError && onFormInitializationError()
        }

    }, [formInitializationError, onFormInitializationError])

    const cardStyle = {
        style: {
            base: {
                color: "#32325d",
                fontFamily: 'Arial, sans-serif',
                fontSmoothing: "antialiased",
                fontSize: "16px",
                "::placeholder": {
                    color: "#32325d"
                }
            },
            invalid: {
                color: "#fa755a",
                iconColor: "#fa755a"
            }
        }
    };

    const handleChange = async (event) => {
        // Listen for changes in the CardElement
        // and display any errors as the customer types their card details
        setDisabled(event.empty);
        setError(event.error ? event.error.message : "");
    };

    const handleSubmit = async (ev) => {
        ev.preventDefault();

        setProcessing(true);

        const payload = await stripe.confirmCardPayment(clientSecret, {
            receipt_email: email,
            payment_method: {
                card: elements.getElement(CardElement)
            }
        });

        if (payload.error) {
            setError(`Payment failed ${payload.error.message}`);
            setProcessing(false);
        } else {
            setError(null);
            setProcessing(false);
            setSucceeded(true);
        }
    };

    return (
        <div className='component-checkout-form'>
            { !formInitializationError
                ? <form id="payment-form" onSubmit={handleSubmit}>
                    <input
                        type="text"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Enter email address"
                    />
                    <CardElement id="card-element" options={cardStyle} onChange={handleChange} />
                    <button
                        disabled={processing || disabled || succeeded}
                        id="submit"
                    >
                        <span id="button-text">
                            {processing
                                ? <div className="spinner" id="spinner"></div>
                                : `Pay $${price}`
                            }
                        </span>
                    </button>
                    {/* Show any error that happens when processing the payment */}
                    {error && (
                        <div className="card-error" role="alert">
                            {error}
                        </div>
                    )}
                    {/* Show a success message upon completion */}
                    <p className={succeeded ? "result-message" : "result-message hidden"}>
                        Payment succeeded, we will reach out to you on your order within 48 hours.
                    </p>
                </form>
                : 'Stripe API error: re-open this modal.'
            }
        </div>
    );
}